.notification-box {
  width: 100%;
  background-color: white;
  float: right;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  .MuiButtonBase-root{
    width: 100%;
  }
}

.red-border-top{
  border-top-style: solid;
  border-top-color: #be1317;
  border-top-width: 1px;
}

.red-border-bottom{
  border-bottom-style: solid;
  border-bottom-color: #be1317;
  border-bottom-width: 1px;
  margin-bottom: 10px!important;
}

.row-notification {
  padding: 15px 0px;
  margin: 0px 20px!important;
  display: flex;
  border-bottom-color: #eecd9b;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.background-notification {
  background: rgba(254, 172, 10, 0.2); 
}

.title-notification{
    background: rgba(254, 172, 10, 0.3); 
    padding: 5px 25px;
    text-transform: uppercase;  
    font-size: 12px;
    border-bottom-color: #eecd9b;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-top-color: #eecd9b;
    border-top-width: 1px;
    border-top-style: solid;
}

.img-notification{
  position: relative;
  top:5px;
  padding: 0 5px;
}
