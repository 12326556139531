.story-table {
  width: 85%;
  h3 {
    color: white;
  }
  .MuiTableCell-root,
  .cell-edit {
    color: white;
    font-size: 20px !important;
    //font-family: "Segoe UI";
    border-color: white !important;
    
  }
  .cell-edit-story{
    white-space: nowrap;
    overflow: hidden;
    width: 25vw !important;
    text-overflow: ellipsis;
  }
  .blue-button {
    margin-right: 20px;
  }
}
