.container-background {
  height: 100vh;
  width: 100vw;
}

.left-panel-background {
  background: url("../../../assets/images/external-left-panal.png") no-repeat
    left 0px top 0px;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  height: 100vh;
}
@media (max-width: 959px) {
  .left-panel-background {
    display: none;
  }
}
.layout-blank-right-panel {
  flex-direction: column;
  align-items: center;
  width: calc(100% - 680px) !important;
}
@media (max-width: 1680px) {
  .layout-blank-right-panel {
    flex-direction: column;
    align-items: center;
    width: 50% !important;
  }
}
.layout-blank-right-panel-column {
  width: 495px;
  min-width: 495px;
  text-align: center;
}
.top {
  display: flex;
  align-items: flex-end;
  /* align-content: flex-end; */
  justify-content: center;
}
.middle {
  padding-top: 50px;
}
.bottom {
  display: flex;
  justify-content: center;
}
.bottom a {
  padding: 0 10px;
  font-size: 16px !important;
  color: #000000 !important;
}
.page-offset {
  height: 100vh;
  widows: 100vw;
}
.MuiButtonBase-root.rounded-radius,
.rounded-radius {
  border-radius: 5px;
}

.logo-top {
  padding: 20px;
  height: 200px;
  img {
    width: 70%;
  }
}

.background{
  height: 100%;
}

 .background-white-top{
   background: url("../../../assets/images/background-top.svg") top left no-repeat fixed;
    width: 100%;
    height: 100%;
    position: fixed;
    pointer-events: none;
    z-index: 3;
    background-size: 16%;
 }

 .background-logo{
    background: url("../../../assets/images/logo.png") top left no-repeat fixed;
     width: 100%;
     height: 100%;
     position: fixed;
     pointer-events: none;
     z-index: 3;
     background-position: 15px 15px;
     background-size: 10%;
 }

 .background-white-bottom{
  background: url("../../../assets/images/background-bottom.svg") bottom right no-repeat fixed;
   width: 100%;
   height: 100%;
   position: fixed;
   pointer-events: none;
   z-index: 11;
   background-size: 20%;
}

.background-bg-team{
  background-image: url("../../../assets/images/bird.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right 40px bottom 40px;
  background-size: 20%;

}

.background-bg-child{
  background-image: url("../../../assets/images/background-img-child.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right 40px bottom 40px;
  background-size: 20%;
}

.background-bg-admin{
  background-image: url("../../../assets/images/background-img-admin.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right 40px bottom 40px;
  background-size: 20%;
}
