.bg{
   height: 100%;
   min-height:100vh;
   background-color: #EECD9B ;

}

.bg-child{
  height: 100%;
  min-height:100vh;
  background-color: #EECD9B ;
  background-attachment: fixed;
  background-repeat: repeat;
}

.bg-admin{
  height: 100%;
  min-height:100vh;
  background-color: #D8DEE4 ;
  background-attachment: fixed;
  background-repeat: repeat;
}

.bg-team{
  height: 100%;
  min-height:100vh;
  background-color: #C7EAF4 ;
  background-attachment: fixed;
  /* background-repeat: repeat; */
  /* background: url("../../assets/images//background-top.svg") top left no-repeat fixed,
  url("../../assets/images/background-bottom.svg") bottom right
  no-repeat fixed; */
}

.bg-dreamcircle{
  height: 100%;
  min-height:100vh;
  background-color: #FFFCE2 ;
  background-attachment: fixed;
}

@media (max-width: 959px){
   .bg{
        background-image:  none;
    }
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
