.wheel {
  position: absolute;
  height: 195px;
  width: 195px;
  background-color: #fff;
  border-radius: 50%;
  top: -24px;
  left: 29px;
  .second-color-picker {
    position: absolute!important;
    top: 5px;
    left: 5px;
    width: 185px;
    height: 185px;
    g {
      padding: 10px;
    }
  }

  .second-color-picker:hover{
    cursor: crosshair;
  }
}
#Group_9601:hover {
  transform: translate(-290px, -80px);
}
#Group_9602:hover {
  transform: translate(-290px, -78px);
}
#Group_9603:hover {
  transform: translate(-284px, -83px);
}
#Group_9604:hover {
  transform: translate(-286px, -76px);
}
#Group_9605:hover {
  transform: translate(-284px, -75px);
}
#Group_9606:hover {
  transform: translate(-282px, -75px);
}
#Group_9607:hover {
  transform: translate(-281px, -78px);
}
#Group_9608:hover {
  transform: translate(-277px, -75px);
}
#Group_9609:hover {
  transform: translate(-276px, -77.5px);
}
#Group_9610:hover {
  transform: translate(-275px, -79.5px);
}
#Group_9611:hover {
  transform: translate(-275px, -82px);
}

#Group_9612:hover {
  transform: translate(-275px, -84px);
}
#Group_9613:hover {
  transform: translate(-275px, -86.5px);
}
#Group_9614:hover {
  transform: translate(-277px, -88px);
}
#Group_9588:hover {
  transform: translate(-277px, -90px);
}
#Group_9589:hover {
  transform: translate(-280px, -90px);
}
#Group_9590:hover {
  transform: translate(-282px, -91px);
}
#Group_9594:hover {
  transform: translate(-284px, -91px);
}
#Group_9595:hover {
  transform: translate(-286px, -91px);
}
#Group_9596:hover {
  transform: translate(-288px, -89px);
}
#Group_9597:hover {
  transform: translate(-289px, -88px);
}
#Group_9598:hover {
  transform: translate(-290px, -87px);
}
#Group_9599:hover {
  transform: translate(-290px, -85px);
}
#Group_9600:hover {
  transform: translate(-290px, -83px);
}

#Group_9660:hover {
  transform: translate(-218px, -81px);
}
#Group_9661:hover {
  transform: translate(-218px, -81px);
}
#Group_9642:hover {
  transform: translate(-206px, -86px);
}
#Group_9643:hover {
  transform: translate(-206px, -88px);
}
#Group_9644:hover {
  transform: translate(-203px, -85px);
}
#Group_9646:hover {
  transform: translate(-218px, -81px);
}

#cs{ 
    display:none;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 185px;
    height: 185px;
  }

  .circle{
    stroke: #646464;
    stroke-width: 1px;
    stroke-dasharray: 2,2;
    stroke-linejoin: round;
  }
