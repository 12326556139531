/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Segoe UI Regular';
font-style: normal;
font-weight: normal;
src: local('Segoe UI Regular'), url('../fonts/Segoe UI.woff') format('woff');
}


@font-face {
font-family: 'Segoe UI Italic';
font-style: normal;
font-weight: normal;
src: local('Segoe UI Italic'), url('../fonts/Segoe UI Italic.woff') format('woff');
}


@font-face {
font-family: 'Segoe UI Bold';
font-style: normal;
font-weight: normal;
src: local('Segoe UI Bold'), url('../fonts/Segoe UI Bold.woff') format('woff');
}


@font-face {
font-family: 'Segoe UI Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Segoe UI Bold Italic'), url('../fonts/Segoe UI Bold Italic.woff') format('woff');
}
a{
    font-size: 30px;
    color:#000000;
    text-decoration: none;
}

