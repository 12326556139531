.progressBarContainer{
    position:fixed;
    width:100%;
    height:100%;
    top:0 ;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.loading{
    height: 150px;
    width: 150px;
}