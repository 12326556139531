.way-serpent-empathy {
  background: url("../../../assets/images/bg-empathy.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.way-serpent-cooperation {
  background: url("../../../assets/images/bg-cooperation.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.way-serpent-spiritual {
  background: url("../../../assets/images/bg-spiritual.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.way-serpent-self {
  background: url("../../../assets/images/bg-self.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.way-serpent-thinking {
  background: url("../../../assets/images/bg-thinking.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.img-circle {
    filter: invert(20%) sepia(88%) saturate(5691%) hue-rotate(350deg) brightness(75%) contrast(98%);
}

.count-star {
  font-size: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #0c2a70;
  font-weight: 600;
}

.circle-title {
  padding: 5px 15px;
  display: flex;
  border-radius: 25px;
  width: fit-content;
  margin: auto;
}

.circle-no-select{
    background-color: #feac0a;
    span {
        margin: auto;
        color: #0c2a70;
      }
}

.circle-select{
    background-color: #BE1317;
    span {
        margin: auto;
        color: #FFFCE2;
      }
}

.star-select{
    filter: invert(0%) sepia(0%) saturate(1320%) hue-rotate(202deg) brightness(75%) contrast(94%);
}

.star-name{
    color: #0c2a70;
    font-size: 14px;
    position: absolute;
    width: fit-content;
    padding-left: 5px;
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-transform: capitalize;
}