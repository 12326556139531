.box-right {
}

.feed-list {
}

.box-left {
}
.title {
  .MuiLink-root {
    padding: 0 !important;
  }
}

.search-text {
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-bottom: gray;
  border-bottom-width: 0.4px;
  border-bottom-style: solid;
  width: 70%;
}

.row-search{
    position: sticky;
    top: 115px;
    z-index: 2;
    display: flex;
}

.color-bg-team{
  background: #C7EAF4;
}
.color-bg-admin{
  background: #D8DEE4;
}
.color-bg-child{
  background: #EECD9B;
}

.row-search-bg-team{
  @extend .row-search;
  @extend .color-bg-team;
}
.row-search-bg-admin{
  @extend .row-search;
  @extend .color-bg-admin;
}
.row-search-bg-child{
  @extend .row-search;
  @extend .color-bg-child;
}

.header-bg-team{
  @extend .header;
  @extend .color-bg-team;
}

.header-bg-admin{
  @extend .header;
  @extend .color-bg-admin;
}

.header-bg-child{
  @extend .header;
  @extend .color-bg-child;
}

.header{
  position: sticky; 
  top: 0;
  z-index: 10;
}