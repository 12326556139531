.information-story{
    border-bottom: solid;
    border-bottom-width: 1px;
    //display: grid;
    .name{
        font-size: 20px!important;
        font-weight: 800;
        text-transform: capitalize;
        padding: 0!important;
        //font-family: "Segoe UI"!important;
    }
    
    .creation-on{
        font-size: 14px;

    }
    .story{
        padding: 10px 0px;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        //font-family: "Segoe UI";
    }
    .has-reaction{
        color: blue;
        font-weight: 900;
    }
}