.archievement-table{
    width: auto!important;
    //left: 30px;
    margin-left: 50px;
    .MuiTableCell-root{
        font-size: 14px!important;
        padding: 5px 16px!important;
        border-top: none!important;
        border-bottom: none!important;
    }
}

.title{
    display: flex;
    padding: 5px;
    img{
        width: 40px;
        padding-right: 20px;
    }
    span{
        color: #BE1317;
        font-size: 16px;
        font-weight: 500;
        margin-top: auto;
    margin-bottom: auto;
    }
}

.MuiTable-root th{
    border-top: none!important;
}