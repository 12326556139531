.button-reaction{
    .open{
        //background-color: #BE1317;
        background-image: url("../../../assets/images/red-select-icon.svg");
    }
    .no-open{
        //background-color: white;
        background-image: url("../../../assets/images/select-icon.svg");
    }
    
    .btn{
        text-transform: capitalize;
    }
}