.user-table{
    .MuiTableCell-root{
        font-size: 14px!important;
        padding: 5px 5px!important;
        border-top: none!important;
        border-bottom: none!important;
        text-transform: none !important
    }
    .name{
        text-transform: capitalize !important;
        font-weight: 800;
        font-size: 14px;
    }
    .MuiLink-root{
        padding: 0 !important;
    }
}