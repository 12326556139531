.menu-header{
    padding-top: 25px!important;
    padding-left: 25px!important;
    padding-right: 25px!important;
    padding-bottom: 10px!important;
    display: flex;
    height: 80px;
    //background: url("../../../assets/images//background-top.svg") top left no-repeat fixed;
}

.extra-menu{
    padding-right: 35px;
    right: 0;
    top: 58px;
    position: absolute;
}

.notification{
    padding-right: 125px;
    width: 450px;
    right: 0;
    top: 58px!important;
    left: unset!important;
    position: absolute;
}

.MuiLink-root{
    font-size: 16px;
    padding: 20px;
    color: black!important;
}

.menu-selected{
    color: #BE1317!important;
}

.MuiBadge-colorSecondary {
    background-color: #BE1317!important;
}

.extra-box-menu{
    background-color: white;
    float: right;
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
    border-top-style: solid;
    border-top-color: #BE1317;
    border-top-width: 1px;
}

.header-box-menu{
    float: right;
}

.user-name-menu{
    display: inline!important;
    text-transform: capitalize;
}

.logo-top {
    img {
      width: 45%;
    }
  }