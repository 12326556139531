.cell-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 8vw;
}

.object-table{
    left: 30px;
    .MuiTableCell-root{
        font-size: 14px!important;
        padding: 1px 16px!important;
        border-top: none!important;
        border-bottom: none!important;
    }
}

.item-select{
    color: #376CA7!important;
    font-weight: 500!important;
}

