.modal-component{
    .MuiDialog-paper {
        width: 55% !important;
        height: 80% !important;
      }
      
      .MuiDialog-container{
          width: 100% !important;
      }
      
      .MuiDialog-paperWidthSm {
          max-width: none!important;
      }

      .MuiGrid-root .MuiPaper-root{
        width: 85% !important;

      }
      .behaviour-plan{
        .MuiTableCell-head, .MuiTableCell-body, .MuiTypography-caption{
          color:#ffffff!important;
      }
      .MuiTable-root th{
          border-top:none!important;
      }
      .MuiTableCell-root{
          border-bottom: 1px solid #ffffff!important;
      }
      }
}


.modal-box {
  width: 100%;
  height: 100%;
  box-shadow: 5px;
  background: url("../../../assets/images/popup-white-bg.png") top right
    no-repeat;
  .logo-img {
    width: 60%;
  }
}

.modal-box-notification {
  width: 100%;
  height: 100%;
  box-shadow: 5px;
  background: url("../../../assets/images/background-notification.png") top right
    no-repeat;
  .logo-img {
    width: 60%;
  }
}



.modal-title {
  color: white !important;
  font-size: 40px !important;
  //font-family: "Segoe UI" !important;
  text-transform: capitalize;
  position: absolute !important;
  bottom: 0;
}
.reset-modal-title{
  @extend .modal-title;
  color: #BE1317 !important;
}
.modal-title-story {
  color: black !important;
}

.form-story {
  width: 90% !important;
}

.backgroud-behaviour {
  background-image: url("../../../assets/images/new_story_popup_behaviour.png");
  background-repeat: no-repeat;
  background-position: right 210px top 20px;
  padding-top: 44px;
  background-size: 200px 200px;
}

.backgroud-fish {
  background-image: url("../../../assets/images/story_fish.png");
  background-repeat: no-repeat;
  background-position: right 160px top 20px;
  padding-top: 44px;
  background-size: 350px 250px;
}

.background-behaviour-fish {
  background-image: url("../../../assets/images/behavioural-plans-popup-empathy.png");
  background-repeat: no-repeat;
  background-position: right 160px top 20px;
  padding-top: 44px;
  background-size: 350px 250px;
}

.background-A {
  background-color: #eecd9b !important;
}

.background-B {
  background-color: #c7eaf4 !important;
}

.background-C {
  background-color: #2A877C !important;
}

.details {
  color: #707070;
  font-size: 25px;
}

.cell-edit-story{
  img{
    width: 35px!important;
  }
}

.user-table-list{
  padding-right: 10%;
}
