body {
  margin: 0;
  font-family:  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.MuiTypography-body1, 
.MuiTableCell-root, 
.MuiButton-root, 
.MuiMenuItem-root, 
.MuiTypography-body2, 
.MuiInputBase-input{
  font-family:  'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}

code {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}

.Mui-error{
  position: relative!important;
  left: 0!important;
}
