.form-new-story{
    .row-form-story{
        width: 100%!important;
    }
    .text-story{
        width: 100%!important;
        .MuiInputBase-root{
            width: 100%!important;;
        }
    }
    .MuiFormControl-marginNormal {
        margin-top: 0;
        margin-bottom: 0;
    }
    input, .MuiInputBase-root{
        color: black!important;
    }
    .MuiTextField-root{
        width: 250px;
    }

    .loading{
        color: green;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -12px;
    }
}