.container-background{
    height: 100vh;
    width: 100vw;
}
.admin-left-panel-background{
    background: url('../../../assets/images/admin-left-panel-background.png') no-repeat left 0px top 0px ;
    /* -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain; */
   /* height: 100vh; */
   
}
@media (max-width: 959px){
    .left-panel-background{
        display:  none;
    }   
}
.admin-layout-blank-right-panel{
    flex-direction: column;
    align-items: flex-start;
    margin-left :20px;
    width:calc(100% - 441px) !important;
}

.layout-blank-right-panel-column{
    width:495px;
    min-width: 495px;;
    text-align: center;
}
.admin-logo{
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.admin-menu{
    display: flex;
    flex-direction: column;
    height: 230px;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left:50px;
    width: 100%;;
  
}
.admin-menu a{
    width:100%;
    padding-left: 60px
}

.admin-menu  .active
{
    color:#BE1317;
    line-height: 80px;
    background-image: url('../../../assets/images/menuSelected.svg');
    background-position: left 0px;
    background-repeat: no-repeat;
}
 .fish{
    margin-top:25px;
}
.bird{
    margin-top:30px;  
}
.admin-top-menu{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin :30px 100px 0 0; 
    .MuiButton-root{
        text-transform: capitalize!important;
        font-weight: 400!important;
    }
}
.admin-top-menu-small-screen{
   @extend .admin-top-menu;
   margin: 30px 30px 0 0 ;
}
.admin-top-menu a, .admin-top-menu p{
 font-size: 20px;
//  line-height: 90px; 
}
.admin-left-manu{
    color:#000000!important;
    font-size: 30px!important;
}
.user-name-container{
    width:  120px!important;
    max-width: 120px!important;
    flex-basis: 120px!important;
    .user-name{
        // background-image: url('../../../assets/images/user-avatar.png');   
        // background-position: right 0px;
        // background-repeat: no-repeat;
        // width:170px; 
        // margin-right: 10px!important;
        font-size: 20px!important;
        text-transform: capitalize!important;
    }
}
.logout{
    // background-image: url('../../../assets/images/logout-foot-print.png');   
    // background-position: right 0px;
    // background-repeat: no-repeat;
 
    font-size:20px!important;
}
.avatar-logo{
    width:  60px!important;
    max-width: 60px!important;
    flex-basis: 60px!important;

}
.logout-logo{

    width:  90px!important;
    max-width: 90px!important;
    flex-basis: 90px!important;
}
.trasnparent-muipaper-root{
    background-color: transparent!important;
    box-shadow: none!important;
    color:#707070!important;
    padding-top:55px;
}
.MuiTableCell-head{
    font-weight: bold!important;
}

.MuiPaper-root th{
    background-color: transparent!important;
}
.MuiTableCell-root{
    font-size: 20px!important;
    border-bottom: 1px solid #707070!important;
    padding: 16px 8px;
}
.MuiTableBody-root .MuiTableCell-root{
    text-transform: capitalize!important;
    .MuiTypography-root{
        font-size: 20px!important;
    }
}
.MuiInputBase-input{
    background-color: transparent!important;  
}

h2{
    font-size: 40px;
    color:#000000!important;
    margin:0px !important;
    /* width:20%; */
    font-weight: normal;
    line-height: 1.7;
    /* margin-block-end:0px !important; */
}
.MuiInput-input{
    width:250px!important;
}
.MuiSelect-select{
    width:234px !important;
}
.MuiList-root.MuiMenu-list{
    background-color: #ffffff!important;
}



.button{
    text-transform: capitalize!important;
    padding: 4px 16px!important;
}
.orange-button, .cancel-button{
    @extend .button;
    background-color: #F6921E!important;
    color:#ffffff!important;
    width: 160px!important;
}
.green-button{
    @extend .button;
    background-color: #2A877C!important;
    color:#ffffff!important;
    width: 160px!important;
}

.view-profile{
    @extend .orange-button;
    width:auto!important;
    height: 50px!important;
    font-size: 28px!important;

}

.white-button{
    @extend .button;
    background-color: white!important;
    color:black!important;
    width: 160px!important;
}

.save-button{
    @extend .button;
    background-color: #2A877C!important;
    color:#ffffff!important;
    width: 160px!important;
}

.blue-button{
    @extend .button;
    background-color: #376CA7 !important;
    color:#ffffff !important;
    width: 170PX!important;
    //height: 30PX!important;
    //font-size: 15px!important;
    //font-weight: 400!important;
}

.red-button{
    @extend .button;
    background-color: #BE1317 !important;
    color:#ffffff !important;
    width: auto!important;
    // height: 30PX!important;
    // font-size: 15px!important;
    // font-weight: 400!important;
    // margin: auto!important;
}


.blue-headering{  
      color: #376CA7!important;
}

.MuiToolbar-regular h6{
    @extend .blue-headering;
    font-size: 25px!important;     
    font-weight: 700!important;    
}
.MuiToolbar-regular div:first-child {
    // width:250px!important;
}
.MuiToolbar-regular div:nth-child(2) {
    // width:calc(100% - 250px) !important;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.avatar-heading-container{
    padding-left:45px;
    margin-top:20px;
    margin-bottom: 70px;;
}
.avatar-name{
    font-size: 40px!important;
    margin: 0px 0  25px!important;
    text-transform: capitalize!important;
}
.avatar-name-twice{
    font-size: 40px!important;
    line-height: 1!important;
    text-transform: capitalize!important;
}
.avatar-name-twice:nth-child(3){
    font-size: 40px!important;
    margin: 0px 0  20px!important;
    text-transform: capitalize!important;
}
h4{
    @extend .blue-headering;
   font-size: 25px!important;     
   font-weight: 700!important; 
}
.user-detail-container-details{
    width: 50%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
}
.user-detail-container-isAdmin{
    width: 50%;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    display: flex;
    p{
        font-size:20px!important ;
        font-weight: bold;
    }
}

// .user-detail-container {
//     border-bottom: 1px solid #707070 !important
// }
.row{
    border-bottom: 1px solid #707070 !important;
    padding: 10px!important;
}
.row:first-child{
    border-top: 1px solid #707070 !important;
   
}
.row .MuiInputBase-root{
    font-size: 20px!important;
}
.row .MuiInputBase-root:nth-child(2){
    width: 50%;
}
.row .label{
    font-weight: bold;

}
.label-disable{
    font-weight: 400!important;
}
.row:hover{
    background-color: rgba(0, 0, 0, 0.07);
}

.row  .MuiInput-underline.Mui-error:after,.row .MuiInput-underline:after, .row .MuiInput-underline:before{
    border:none;
}
.row .MuiFormHelperText-root.Mui-error{
    position: absolute;
    left:250px;
    width: 250px;;
}

.add-user .row .MuiInputBase-root:nth-child(2){
    width: 500px;
}

.add-user{
    .MuiSelect-selectMenu{
        width: 100%!important;
      }
}

.MuiTable-root th{
    border-top: 1px solid #707070 !important;
}
.user-detail-header
{
    margin-bottom: 35px!important;
}
.user-stroies-header, .dream-circle-header{
    // margin-top: 70px!important;
    .MuiPaper-root{
        width: 100%!important;
    }
}
.remove-top-margin{
    margin-top: 00px!important;
    .user-stroies-header{
        margin-top: -77px!important;
    }
    .trasnparent-muipaper-root{
        padding-top: 0px !important;
    }
}
.remove-top-margin-dream-circle{
    margin-top: 00px!important;
    .user-stroies-header{
        margin-top: -55px!important;
    }
    .trasnparent-muipaper-root{
        padding-top: 0px !important;
    }
}
.remove-top-margin-team-member{
    margin-top: 12px!important;
    .user-stroies-header{
        margin-top: -55px!important;
    }
    .trasnparent-muipaper-root{
        padding-top: 0px !important;
    }
}
.avatar-image-container{
    min-width: 170px;;
}
.grid-button{
   @extend  .save-button;
}

.margin-top{
    margin-top:35px!important;
}

.button-container{
    display: flex;
    justify-content: flex-end;
}


.MuiInputBase-inputMultiline{
    height: auto!important;
}
.MuiPickersModal-dialogRoot{
    background-color: #ffffff!important;
    .MuiPaper-root{
        background-color: #ffffff;
    }
}
.MuiTypography-colorPrimary{
    color: #F6921E!important;
}
.MuiPickersDay-daySelected{
    background-color: #F6921E!important;
}
.MuiPickersToolbar-toolbar{
    background-color: #F6921E!important;
    .MuiPickersToolbarButton-toolbarBtn{
       h4, h6{
        color:#ffffff!important;
       }
    }
}
.MuiDialogActions-root{
    .MuiButton-textPrimary,
    .MuiButton-label{
        color:#F6921E!important;
    }
}
.child-background .MuiDialog-paperScrollPaper{
    background-image:url("../../../assets/images/popup-white-bg.png")!important;
    background-repeat: no-repeat;
    background-color: #2A877C !important;
    width: 1137px!important;
    min-height: 748px!important;
    max-width: unset!important;
    color:#ffffff;
    display: grid;

}
.admin-background .MuiDialog-paperScrollPaper{
    background-image:url("../../../assets/images/popup-white-bg.png")!important;
    background-repeat: no-repeat;
    background-color: #FEAC0A !important;
    width: 1137px!important;
    min-height: 748px!important;
    max-width: unset!important;
    color:#ffffff;
    display: grid;

}

.MuiDialogContent-root{
    overflow-y: hidden!important;
}

.popup-edit-details .MuiDialog-paperScrollPaper{
  
  
    h2{
        justify-content: flex-end;
        display: flex;
    }
    h2 img{
        width:28px;
    }
    .MuiInputBase-root .label{
        width:200px;
    }
    .MuiInputBase-input{
        color:#ffffff;
    }
   
    .edit-detail-form, .edit-child-behaviours-grant-star, .edit-child-behaviours-fish{
        margin-left:45px;
        margin-bottom: 50px;
         .MuiFormHelperText-root{
             display: none!important;
         }
    }
    .row:first-child, p {
        border-top: none !important;
        font-size: 25px!important;
        color:#ffffff;
        font-weight: 500;
        margin-top: 25px;
    }
    .row{
        border-bottom: 1px solid #ffffff !important;
    }
    .label{
        width:200px;
    }
    .MuiTextField-root{
        width:250px;
        .MuiInput-formControl{
            width:250px;
        }
    }
    // .MuiInputBase-root .label{
    //     width:25%;
    // }
    .tow-column{
        flex-direction: row;
        display: flex ;
    }
    .DOB-gender{
        // flex-direction: row;
        .MuiTextField-root, .MuiInput-formControl{
            //max-width: 190px;
        }
        .MuiInputBase-adornedEnd{
            width: 100%!important;
            margin: auto;
        }

    }

    .MuiSelect-root{
        width: 100%!important;
    }
    .second-column{
        // flex-direction: row;
        // .label{
        //     width:125px;
        // }
        .MuiTextField-root, .MuiInput-formControl{
            width: 150px;
        }

    }
    .MuiSelect-icon, .MuiSvgIcon-root{
        color:rgba(255,255,255, .8)!important;
    }
    .popup-heading{
        justify-content: flex-start;
        color:#ffffff!important;
        font-size: 40px;
        padding-left :25px;
        line-height: 52px;
        text-transform: capitalize;

    }
        
      .button-container {
          justify-content: flex-start;
      }
      .edit-child-shells{
          background-image: url(../../../assets/images/edit-details-popup.png);
          background-size: 360px;
          background-repeat: no-repeat;
          background-position: right 0px top;
          padding-top:15px;
      }
      .edit-child-tools{
        background-image: url(../../../assets/images/new-story-pop-up-story-one.png);
        background-repeat: no-repeat;
        background-position: right 210px top 20px;
        padding-top: 44px;
        background-size: 225px 229px;
        .trasnparent-muipaper-root{
            background-color: transparent!important;
            box-shadow: none!important;
            color:#ffffff!important;
            padding-top:55px;
        }
        .MuiTableCell-head, .MuiTableCell-body, .MuiTypography-caption{
            color:#ffffff!important;
        }
        .MuiTable-root th{
            border-top:none!important;
        }
        .MuiTableCell-root{
            border-bottom: 1px solid #ffffff!important;
        }
    }
    .edit-story-fish{
        background-image: url(../../../assets/images/story_fish.png);
        background-repeat: no-repeat;
        background-position: right 70px top 30px;
        padding-top: 44px;
        background-size: 400px 282px;
        overflow-y: auto!important;
        .trasnparent-muipaper-root{
            background-color: transparent!important;
            box-shadow: none!important;
            color:#000000!important;
            padding-top:55px;
        }
        .MuiTableCell-head, .MuiTableCell-body, .MuiTypography-caption{
            color:#000000!important;
        }
        .MuiTable-root th{
            border-top:none!important;
        }
        .MuiTableCell-root{
            border-bottom: 1px solid #000000!important;
        }
    }
    .edit-child-add-team{
        background-image: url(../../../assets/images/team-members-popup.png);
        background-repeat: no-repeat;
        background-position: right 70px top 15px;
        padding-top: 44px;
        background-size:358px 650px;
        .trasnparent-muipaper-root{
            background-color: transparent!important;
            box-shadow: none!important;
            color:#000000!important;
            padding-top:55px;
        }
        .MuiTableCell-head, .MuiTableCell-body, .MuiTypography-caption{
            color:#000000!important;
        }
        .MuiTable-root th{
            border-top:none!important;
        }
        .MuiTableCell-root{
            border-bottom: 1px solid #000000!important;
        }
    }
    
    .edit-teamMember-platypus{
        background-image: url(../../../assets/images/platypus.png);
        background-repeat: no-repeat;
        background-position: right 25px top -50px;
        padding-top: 44px;
        background-size: 441px 480px;
        .trasnparent-muipaper-root{
            background-color: transparent!important;
            box-shadow: none!important;
            color:#ffffff!important;
            padding-top:55px;
        }
        .MuiTableCell-head, .MuiTableCell-body, .MuiTypography-caption{
            color:#ffffff!important;
        }
        .MuiTable-root th{
            border-top:none!important;
        }
        .MuiTableCell-root{
            border-bottom: 1px solid #ffffff!important;
        }
    }
    .edit-teamMember-AddChild{
        background-image: url(../../../assets/images/team-member-child-popup.png);
        background-repeat: no-repeat;
        background-position: right 75px top 55px;
        padding-top: 44px;
        background-size: 227px 280px;
        .trasnparent-muipaper-root{
            background-color: transparent!important;
            box-shadow: none!important;
            color:#ffffff!important;
            padding-top:55px;
        }
        .MuiTableCell-head, .MuiTableCell-body, .MuiTypography-caption{
            color:#ffffff!important;
        }
        .MuiTable-root th{
            border-top:none!important;
        }
        .MuiTableCell-root{
            border-bottom: 1px solid #ffffff!important;
        }
    }
    .edit-child-sotries-shells{
        background-image: url(../../../assets/images/new_story_popup_behaviour.png);
        background-repeat: no-repeat;
        background-position: right 210px top 20px;
        padding-top: 44px;
        background-size: 225px 229px;
        .trasnparent-muipaper-root{
            background-color: transparent!important;
            box-shadow: none!important;
            color:#ffffff!important;
            padding-top:55px;
        }
        .MuiTableCell-head, .MuiTableCell-body, .MuiTypography-caption{
            color:#ffffff!important;
        }
        .MuiTable-root th{
            border-top:none!important;
        }
        .MuiTableCell-root{
            border-bottom: 1px solid #ffffff!important;
        }
    }
    .edit-child-behaviours-shells{
        background-image: url(../../../assets/images/behavioural-plans-popup.png);
        background-repeat: no-repeat;
        background-position: right 210px top 20px;
        padding-top: 44px;
        background-size: 225px 229px;
        .trasnparent-muipaper-root{
            background-color: transparent!important;
            box-shadow: none!important;
            color:#ffffff!important;
            padding-top:55px;
        }
        .MuiTableCell-head, .MuiTableCell-body, .MuiTypography-caption{
            color:#ffffff!important;
        }
        .MuiTable-root th{
            border-top:none!important;
        }
        .MuiTableCell-root{
            border-bottom: 1px solid #ffffff!important;
        }
    }
    .edit-child-behaviours-fish{
        background-image: url(../../../assets/images/behavioural-plans-popup-empathy.png);
        background-repeat: no-repeat;
        background-position: right 0px top 20px;
        padding-top: 44px;
        background-size: 522px 378px;
        .trasnparent-muipaper-root{
            background-color: transparent!important;
            box-shadow: none!important;
            color:#ffffff!important;
            padding-top:55px;
        }
        .MuiTableCell-head, .MuiTableCell-body, .MuiTypography-caption{
            color:#ffffff!important;
        }
        .MuiTable-root th{
            border-top:none!important;
        }
        .MuiTableCell-root{
            border-bottom: 1px solid #ffffff!important;
        }
        .MuiFormHelperText-root{
            font-size: 12px!important;
            color: red!important;
            margin-top: 5px!important;
        }
    }
    .edit-child-behaviours-grant-star{
        background-image: url(../../../assets/images/grant-new-star-popup.png);
        background-repeat: no-repeat;
        background-position: right 0px top -16px;
        padding-top: 44px;
        background-size: 468px 454px;
        .trasnparent-muipaper-root{
            background-color: transparent!important;
            box-shadow: none!important;
            color:#ffffff!important;
            padding-top:55px;
        }
        .MuiTableCell-head, .MuiTableCell-body, .MuiTypography-caption{
            color:#ffffff!important;
        }
        .MuiTable-root th{
            border-top:none!important;
        }
        .MuiTableCell-root{
            border-bottom: 1px solid #ffffff!important;
        }
    }
    .MuiInput-underline,.Mui-focused{
      border:none!important;
    }
    .Mui-error-label{
        color:#BE1317!important;
        .MuiInputBase-input{
            color:#BE1317!important;
        }
    }

.trasnparent-muipaper-root-grant-new-star{
    background-color: transparent!important;
    box-shadow: none!important;
    color:#707070!important;
    padding-top:55px;
    .MuiToolbar-regular h6{
        color:#ffffff!important;
        font-size: 20px!important;
        font-weight: 400!important;
    }
}
        .MuiTablePagination-toolbar { 
            p{
            font-size:0.875rem!important;
            margin-top: 0px;;
            }
        }
    .MuiFormControl-marginNormal{
        margin-top:0px!important;
    }
    .MuiPaper-elevation1{
        display:none;
    }
    .auto-complete-row{
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #ffffff !important;
        .auto-complete{
            width: 250px!important;
        }
        .MuiAutocomplete-inputRoot{
            padding:0!important;
        }
        .label {
            font-weight: bold;
        }
         .MuiInputBase-root {
            font-size: 20px !important;
        }
        .MuiTextField-root{
            width: 300px; 
        }
        .MuiOutlinedInput-root, .Mui-focused, .MuiOutlinedInput-root, .MuiFormControl-marginNormal{
            border:none!important;
            border-width: 0px!important;
            
        }
        .MuiInput-underline.Mui-error:after,.row .MuiInput-underline:after, .row .MuiInput-underline:before{
            border-width: 0px!important;
            border:none;
        }
    }
    
}
.story-background .MuiDialog-paperScrollPaper{
    background-image:url("../../../assets/images/popup-white-bg.png")!important;
    background-repeat: no-repeat;
    background-color: #EECD9B !important;
    width: 1137px!important;
    min-height: 748px!important;
    max-width: unset!important;
    color:#000000!important;
    .MuiInputBase-input{
        color:#000000!important;;
    }
    .popup-heading{
        justify-content: flex-start;
        color:#000000!important;
        font-size: 40px;
        padding-left :25px;
        line-height: 52px;
        text-transform: capitalize;

    }
    .add-user .row .MuiInputBase-root:nth-child(2){
        width: 400px;
    }
    .edit-detail-form{
        margin-left:45px;
    }
    .row:first-child, p {
        border-top: none !important;
        font-size: 25px!important;
        color:#000000!important;;
        font-weight: 500;
        margin-top: 25px;
    }
    .row{
        border-bottom: 1px solid #000000 !important;
    }
    .MuiSelect-icon, .MuiSvgIcon-root{
        color:rgba(0,0,0, .8)!important;
    }
    .full-width_control{
        margin-top:25px;
        width: 100%;
        .MuiInputBase-formControl{
            width: 90%!important;
            .MuiInputBase-inputMultiline{
                width: 100%!important;
            }
        }
    }
}

.select-behaviour{
    .MuiInput-underline{
        width: 50%!important;
    }

}

.back-button{
    width:245px!important;
}

  @media (max-width: 1700px){
    .admin-layout-blank-right-panel{
       flex-direction: column;
        align-items: center;
        width:50% !important;
    }
    .remove-top-margin{
        margin-top: 00px!important;
        .user-stroies-header{
            margin-top: 0px!important;
        }
        .trasnparent-muipaper-root{
            padding-top: 0px !important;
        }
    }
    .remove-top-margin-dream-circle{
        margin-top: 00px!important;
        .user-stroies-header{
            margin-top: 0px!important;
        }
        .trasnparent-muipaper-root{
            padding-top: 0px !important;
        }
    }
    .remove-top-margin-team-member{
        margin-top: 12px!important;
        .user-stroies-header{
            margin-top: 0px!important;
        }
        .trasnparent-muipaper-root{
            padding-top: 0px !important;
        }
    }
    }
@media (max-width: 768px)
{

    .background-white-top-tablet{
        background: url("../../../assets/images/background-top.svg") top left no-repeat fixed;
         width: 100%;
         height: 20vh;
         pointer-events: none;
         position: unset;
         z-index: 3;
         background-size: 30%;
      }
    .admin-layout-blank-right-panel{
        flex-direction: column;
         align-items: center;
         width:90% !important;
     }
}
.about-me-label{
    align-items: baseline!important;
}
.about-me{
    width: calc(100% - 172px)!important;
    .MuiInputBase-root, textarea{
        width: 100%!important;
    }
}

.MuiInputBase-formControl{
    textarea{
        text-align: justify;
        padding-right: 10px;
    }
}

#print{
    font-size: 13px!important;
    text-align: justify;
}
  
  @page {
    size: auto;
    margin: 11mm;
  }

  .submitted-by{
    input{
        text-transform: capitalize!important;
    }
  }

  .MuiAutocomplete-root{
      width: 100%;
  }

  .change-password{
      button{
          margin: auto!important;
          width: 170px!important;
      }
  }