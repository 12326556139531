.sub-manu-scroll-style{
    position:relative;
    overflow-y:hidden;
     height:86vh 
}
.sub-manu-scroll-style{
    &:hover{
        position:relative;
         overflow-y:auto;
         height:86vh; 
    }
}
.bg-child{
//Sell Scrollbar

    .sub-manu-scroll-style::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background-image: -webkit-gradient(linear,
                                        left bottom,
                                        left top,
                                        color-stop(0.44, #feac0a),
                                        color-stop(0.72, #feac0a),
                                        color-stop(0.86, #feac0a));
    }

    //Sell Scrollbar
    .sub-manu-scroll-style::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background-image: -webkit-gradient(linear,
                                        left bottom,
                                        left top,
                                        color-stop(0.44, #feac0a),
                                        color-stop(0.72, #feac0a),
                                        color-stop(0.86, #feac0a));
    }

    .sub-manu-scroll-style::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #EECD9B;
        border-radius: 10px;
    }

    .sub-manu-scroll-style::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #EECD9B;
        border-radius: 10px;
    }

    .sub-manu-scroll-style::-webkit-scrollbar
    {
        width: 8px;
        background-color: #EECD9B;
        margin-right: 5px;
    }

    .sub-manu-scroll-style::-webkit-scrollbar
    {
        width: 8px;
        background-color: #EECD9B;
        margin-right: 5px;
    }

}
.bg-team{
    //Sell Scrollbar
        .sub-manu-scroll-style::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            background-image: -webkit-gradient(linear,
                                            left bottom,
                                            left top,
                                            color-stop(0.44, #0e6077),
                                            color-stop(0.72, #0e6077),
                                            color-stop(0.86, #0e6077));
        }
    
        //Sell Scrollbar
        .sub-manu-scroll-style::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            background-image: -webkit-gradient(linear,
                                            left bottom,
                                            left top,
                                            color-stop(0.44, #0e6077),
                                            color-stop(0.72, #0e6077),
                                            color-stop(0.86, #0e6077));
        }
    
        .sub-manu-scroll-style::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #C7EAF4;
            border-radius: 10px;
        }
    
        .sub-manu-scroll-style::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #C7EAF4;
            border-radius: 10px;
        }
    
        .sub-manu-scroll-style::-webkit-scrollbar
        {
            width: 8px;
            background-color: #C7EAF4;
            margin-right: 5px;
        }
    
        .sub-manu-scroll-style::-webkit-scrollbar
        {
            width: 8px;
            background-color: #C7EAF4;
            margin-right: 5px;
        }
    
    }
.bg-admin{
    //Sell Scrollbar
        .sub-manu-scroll-style::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            background-image: -webkit-gradient(linear,
                                            left bottom,
                                            left top,
                                            color-stop(0.44, #93999e),
                                            color-stop(0.72, #93999e),
                                            color-stop(0.86, #93999e));
        }
    
        //Sell Scrollbar
        .sub-manu-scroll-style::-webkit-scrollbar-thumb
        {
            border-radius: 10px;
            background-image: -webkit-gradient(linear,
                                            left bottom,
                                            left top,
                                            color-stop(0.44, #93999e),
                                            color-stop(0.72, #93999e),
                                            color-stop(0.86, #93999e));
        }
    
        .sub-manu-scroll-style::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #D8DEE4;
            border-radius: 10px;
        }
    
        .sub-manu-scroll-style::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #D8DEE4;
            border-radius: 10px;
        }
    
        .sub-manu-scroll-style::-webkit-scrollbar
        {
            width: 8px;
            background-color: #D8DEE4;
            margin-right: 5px;
        }
    
        .sub-manu-scroll-style::-webkit-scrollbar
        {
            width: 8px;
            background-color: #D8DEE4;
            margin-right: 5px;
        }
    
    }
