//SCSS about menu
.item-menu {
  padding: 5px;
  .is-selected {
    background: url("../../../assets/images/background-menu-selected.png")
      no-repeat;
    @extend .item;
  }

  .not-selected {
    background: url("../../../assets/images/background-menu-not-selected.png")
      no-repeat;
    @extend .item;
  }

  .item {
    width: 100%;
    height: 50px;
  }

  .label-manu {
    position: relative;
    top: 20px;
    left: 24px;
    font-size: 21px;
  }

  .MuiButtonBase-root {
    text-align: left;
    text-transform: capitalize;
  }
}

// SCSS about sub menu
.item-sub-menu {
  width: 100px;
  height: 100px;
  margin: auto;
  padding: 5px;
  .is-selected {
    background: url("../../../assets/images/background-submenu-selected.png")
      no-repeat;
    background-size: contain;
    @extend .item;
  }

  .not-selected {
    background: url("../../../assets/images/background-submenu-not-selected.png")
      no-repeat;
    background-size: contain;
    @extend .item;
  }

  .item {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .MuiButtonBase-root {
    display: initial;
    padding: 2px 2px;
  }

  img {
    width: 70%;
    position: relative;
    margin: auto;
    
  }
  .selected-image{
    position: absolute;
    width: 30px;
    right: 2px;
    top: 68px;
  }
}
