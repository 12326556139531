.avatar-on-page{
    border-radius: 50%;
    width: 100%;
    background-color: white;
    height: 100%;
    margin: auto;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;

    .button-close{
        position: absolute;
        bottom: 149px;
        left: 615px;
        z-index: 2;
    }

    .button-save{
        position: absolute;
        bottom: 50px; 
        left: 520px;
        height: 135px;
        width: 147px;
    }

    .button-color{
        position: absolute;
        height: 200px;
        width: 200px;
    }
    svg{
        position: absolute;
        top:185px;
    }
    .tool{
        position: absolute;
         height: 581px;
    }
}
.character-container{
    width: 70%;
    height: 74%;
}
.border{
    border:1px solid #eb5648!important;
    .square{
        border:1px solid #eb5648!important;
        background: #fff!important;
    }
}
.rect {
    cursor: move;
    user-select: none;
    z-index: 2;
  }

  .wheel{
      z-index: 2;
  }