.bg-dreamcircle {
  .star-select {
    color: #376ca7;
  }

  .red-button {
    margin: auto !important;
  }

  // .background-bg-dreamcircle{
  //     background: url("../../assets/images/dream-circle-page-bottom.png") bottom right no-repeat, url("../../assets/images/dream-circle-page-top.png") top right no-repeat;
  //     background-size: contain;
  //   }

  .background-bottom {
    background: #FFFCE2 url("../../assets/images/dream-circle-page-bottom.png") bottom
      right no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: fixed;
  }

  .background-top {
    background: url("../../assets/images/dream-circle-page-top.png") top right
      no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
  }

  .background-top-logo {
    background: url("../../assets/images/background-top.svg") top left no-repeat
      fixed;
    background-size: 16%;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
  }

  .background-logo {
    background: url("../../assets/images/logo.png") top left no-repeat fixed;
    width: 100%;
    height: 100%;
    position: fixed;
    pointer-events: none;
    z-index: 3;
    background-position: 15px 15px;
    background-size: 10%;
  }
  .button-refresh{
    z-index: 2;
    width: 30px;
    float: right;
    font-variant: proportional-nums;
    display: flex;
    align-self: flex-end;
    position: absolute;
    right: 122px;

  }
}
