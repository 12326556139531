.loginPage{ 
//   /  background-color: #ffffff!important;
    .MuiOutlinedInput-input{
    padding-top:10.5px !important;
    padding-bottom: 10.5px !important;
}
 .MuiInputBase-input{
   background-color: #ffffff !important;
   border-radius: 5px;
   height: 17px!important;
}
.MuiBox-root{
    margin-bottom: 26px!important;
}


}

.grid-login{
    .MuiPaper-root{
        max-width: 50%;
        margin: auto;
    }
}

@media (max-width: 970px){
    .grid-left-logo{
        display:  none;
    }   
}

@media (max-width: 500px){
    .grid-login{
        .MuiPaper-root{
            max-width: 70%;
            margin: auto;
        }
    }
    .forgot-password-section{
        a{
            padding-left:0;
        }
    }
}

.MuiOutlinedInput-input .Mui_focused
  .MuiOutlinedInput-notchedOutline{
      border: 2px solid #376CA7 !important;
      }
/* 
.MuiInputLabel-outlined {
   
   
   
} */

.forgot-password-section{ 
    .MuiFormControlLabel-root, a{
     font-size: 20px!important;
     color :#707070!important;
 }

}
 .button{
    text-transform: uppercase!important;
    font-size: 20px!important;
    // padding: 4px 16px!important;
}
 .blue-fullwidth-button{
    @extend .button;
    background-color: #376CA7 !important;
    color:#ffffff !important;
    width: 100%!important;
    font-weight: 400!important;
    margin-top: 20px!important;
    
}

.red-fullwidth-button{
    @extend .button;
    background-color: #BE1317 !important;
    color:#ffffff !important;
    width: 100%!important;
    font-weight: 400!important;
    margin-top: 20px!important;
    
}

.MuiFormControlLabel-label, .MuiOutlinedInput-input{
    font-size: 20px!important;
}
.MuiCheckbox-colorSecondary.Mui-checked{
    color:#BE1317!important;
}

.MuiLinearProgress-colorPrimary{
    background-color: #F55C5F!important; 
}
.MuiLinearProgress-barColorPrimary {
    background-color: #BE1317!important;
}
