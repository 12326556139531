.row-form-about-me{
    width: 100%!important;
}
.text-about-me{
    width: 100%!important;
    .MuiInputBase-root{
        width: 100%!important;;
    }
}

.andress textarea{
    height: 47px!important;
}

.profile-title{
    color: #376CA7!important;
}

.row-title{
    border-bottom: 1px solid #707070 !important;
    padding: 10px!important;
}
.row-title:first-child{
    border-bottom: 1px solid #707070 !important;
   
}
.row-title .MuiInputBase-root{
    font-size: 20px!important;
}
.row .MuiInputBase-root:nth-child(2){
    width: 50%;
}
.row-title .label{
    font-weight: bold;
}

.label-admin .MuiInputBase-input{
    padding-right: 10px;
    text-align: end;
}